// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: Tahoma, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors

$ba-dark-red: #f6543e;
$ba-red: #e45f49;
$ba-yellow: #f2f0a1;
$ba-dark-grey: #575656;
$ba-light-grey: #F0F0F0;
$ba-blue: #008a9e;


$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
