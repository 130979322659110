h1 {
    font-size: 32px;
    color:$ba-dark-grey;
}

h2 {
    font-size: 24px;
    color:$ba-dark-grey;
}

b, strong {
    font-weight:bold!important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {

    font-weight: 500;
    line-height: 1.2;
}
