.form-control {
    background-color:$ba-light-grey;
    @extend .form-control-lg;
    margin-bottom:1rem;

    &.error {
        border:solid 1px red;
    }

}

.login-page, .register-screen {
    .col-form-label {
        display:none;
    }

    .form-control {
        background-color:#F2F2F2;
        @extend .form-control-lg;
        border:1px solid #E6E6E6;
        margin-bottom:1rem;
        &:focus {
            border:solid 1px #575756;
            outline:none;
            box-shadow: inherit;
        }
    }

    .btn {
        padding: 0.55rem;
        min-width:145px;
        font-size: 1.1rem;
        line-height: 1.5;
        border-radius: 0.3rem;
        margin-right:0.25rem;

        &.btn-red {
            @extend .btn-red;
        }

        @include media-breakpoint-down(xs){
            margin-bottom:1rem;
        }

    }

    .forgot-password {
        position: absolute;
        right: 0;
        .btn-lg, .btn-outline-ba, .btn, .btn-group-lg > .btn {
            padding: 0;
            font-size: 0.85rem;
            line-height: 1;
            border-radius: 0.3rem;

        }
        @include media-breakpoint-down(sm){
            display:none!important;
        }
    }

.online-not-for-you {
    border:solid 1px #000;
    h6 {
        color:#000;
        font-size: 0.85rem;
        font-weight: lighter;
    }

}


}

/* JQuery validate errors */

label.error {
    color: red;
    margin-top: -16px;
    display: block;
    padding-bottom: 16px;
    padding-top:4px;
}

.register-buttons {
    text-align: center;
    @include media-breakpoint-up(md){
        text-align:left;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

#bank-details {
    padding-top:12px;
}

.remember-me {
    padding-top:16px;
    padding-bottom:16px;
}

.forgot-password-mobile {
    margin-top:-20px;
    font-size: 16px;
}
