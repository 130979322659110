@include media-breakpoint-down(sm) {
    h3, .h3 {
        font-size: 1.3575rem;
    }

    #sel-income-years, #sel-payee {
        margin-bottom:1rem;
    }

    .claim-income .table-income tr:not(.bg-blue) td input[type=text] {
        max-width: 100px;
    }

    .payee-table {

    }

    .table.payee-table th, .table.payee-table td {
        padding: 0.25rem;
    }

    .capital-expenses-table select, .capital-expenses-table input[type=text] {
        width:100%;
    }

    .payee-table {
        thead {
            display:none;
        }

       tr, .payee-row {
            td {
                width:100%;
                display:inline-block;
                &:last-child {
                    border-bottom:2px solid #000;
                }
            }

           &:last-child {
               border-collapse:separate;
               border-spacing: 0 1em;
           }

           padding-bottom:0.75rem;
        }

    }

    .nav-links-wrapper {
        &.links > li > a, &.links > a {
            color: #636b6f;
            padding: 5px;
            font-size: 11px;
            font-weight: 400;
        }

    }

    .bg-red .links > a, .bg-red .links > li > a {
        padding: 5px;
        font-size: 11px;
        font-weight: 400;
    }

    .logo img {
        margin-top: 3px;
    }
}
