.navbar {
    &.bg-red {
        background-color: $ba-dark-red;
        width: 100%;
        @include media-breakpoint-down(md){
            padding: 0.5rem 2rem;
        }
    }
}

.header-contact {

    display: none;

    @include media-breakpoint-up(md) {
        display:block;
        padding: 0 1rem;
        .phone-number {
            font-size: 1.15em;
            letter-spacing: -0.02em;
            line-height: 1em;
            display: block;
            font-weight: 700;
        }
        .callback {
            font-size: 0.75rem;
            display: inline-block;
            margin: 0;
            margin-top: -5px;
        }
    }

}

.page-header {
    height:300px;
    h1 {
        color:$ba-red;
        font-size: 36px;
        font-weight: normal;
    }
    p {
        color:$ba-dark-grey;
    }
}

.custom-card {
    -webkit-box-shadow: 0px 4px 10px -3px #000000;
    box-shadow: 0px 12px 21px #00000040;
    border:none;



    &.claim-status-box {
     box-shadow: 0px 12px 21px #00000040;
    }

    .custom-list {
        list-style: none;
        li {
            color:$ba-blue;
            font-size:17px;
            a {
                color:$ba-blue;
                font-size:17px;
            }

            &:before {
                font-family: 'FontAwesome';
                font-size:22px;
                content: '\f105';
                margin:0 5px 0 -15px;
                color:$ba-blue;
                font-weight: bold;

            }
        }
    }

}

.title-row-blue, .section-header-row {
    h3 {
        font-weight:normal;
        @include media-breakpoint-down(sm){
            font-size:1.25rem;
        }
    }
    background-color:$ba-blue;
    border-radius:0.5rem;
    color:white;
}

.claim-income {
    select {
        border:solid 1px $ba-red;
        padding:0.45rem;
        color:$ba-red;
        font-weight:bold;
        min-width: 150px;
        height:52px;
        border-radius: 5px;
        margin-right:15px;
    }

    .table-income {
        td, th {
            padding: 0.75rem 0.3rem;
        }

        tr:not(.bg-blue){
            td {
                background-color:#F0F0F0;
                border:solid 1px #fff;
                input[type=text]{
                    background:#F0F0F0;
                    border:none;
                }

             th {
                 padding: 0.75rem 0.3rem;
             }

            }
        }

    }
}


.payee-table {
    td {
        width:20%;
        border:solid 1px #fff;
        background-color:#F0F0F0;
        input[type=text]{
        width:100%;
        border:none;
        background-color:#F0F0F0;
        }
label {
    @include media-breakpoint-up(md){
        display:none;
    }
}
    }
}

.user-dashboard {
.status-wrapper {


    h1,h2,h3,h4 {
        font-weight: lighter;
    }

    h1 {
        color:$ba-red;
        @include media-breakpoint-down(sm){
            font-size:1.75rem;
        }
    }

    h3.rebate-status-outer-heading {
        font-weight: normal;
       padding-left:1.25rem;
        padding-bottom:1.25rem;
    }

    .claim-status-board {
        padding:1.25rem;
    }

    h4 {
        color:#000;
        font-weight:bolder;
        font-size:17px;
    }

}
}

.status-box {
    width:100%;
    position:relative;
    display:block;

    .progressbar {
        counter-reset: step;
        padding:0;
    }
    .progressbar li {
        list-style-type: none;
        width: 18%;
        float: left;
        font-size: 14px;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        color: #7d7d7d;
        z-index:0;

        @include media-breakpoint-down(md){
            width:100%;
        }

    }
    .progressbar li:before {
        width: 62px;
        height: 62px;
        content: '';
        counter-increment: step;
        line-height: 62px;
        border: 2px solid #707070;
        display: block;
        text-align: center;
        margin: 0 auto 10px 0;
        border-radius: 50%;
        background-color: #707070;
        z-index:1;
    }
    .progressbar li:after {
        width: 100%;
        height: 3px;
        content: '';
        position: absolute;
        background-color:#707070;
        top: 25%;
        left: -70%;
        z-index:-1;

    }
    .progressbar li:first-child:after {
        content: none;
    }
    .progressbar li.active {
        color: $ba-blue;
        z-index:9999;
    }
    .progressbar li.active:before {
        border-color: $ba-blue;
        background-color:$ba-blue;
        color:#fff;
        font-family: "FontAwesome";
        content: "\f00c";
        font-size:1.25rem;
        font-weight: lighter;


    }
    .progressbar li.active + li:after {
        background-color: $ba-blue;
    }

}

@include media-breakpoint-down(md){

    .progressbar li {
        margin-bottom: 1.5rem;
        border-bottom:solid 1px #ccc;
        padding-bottom:1.75rem;
    }

    .progressbar li.active:before, .progressbar li:before {
       position: absolute;
        right:0;
        top:-15px;
    }

    .status-box .progressbar li:after {
        width: 0;
        height:0;
    }
}

.login-screen {
    .bg-image {
        background-size: cover!important;
        background-position: center center!important;
    }

    .brand-image {

    }

    .form-box {

    }

    .row.no-gutters {
        margin-right: 0;
        margin-left: 0;

        & > [class^="col-"],
        & > [class*=" col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }

}

.bank-details-wrapper {

}

.claim-info-message {
    display:inline-block;
    @include media-breakpoint-down(sm){
        display:block;
    }
}

ul.proof-docs {
    li {
        padding-bottom:1rem;
    }

}

.upload-form {
    .btn-upload {
        margin-top:0.5rem;
    }
}

ul#expenses-doc-list, ul.income-uploaded-docs-list, .supporting-docs-form ul, ul.proof-docs {
    list-style: none;
    padding-left:0;

    li {
        margin-bottom: 1rem;
        cursor: pointer;
        > a {
            color: $ba-red;
            font-size: 22px;
            font-weight: lighter;
        }
        > p{
            padding-bottom:0;
            margin-bottom:0;
        }
    }
}

.supporting-docs-form {
    max-width: 100%;
    padding: 0;

    .form-group {
        margin-bottom:0;
    }

    ul {
        padding-bottom:0;
        li {
            margin-bottom:0;
        }
    }


}

.income-file-container {
    label {
        margin-bottom:0;
    }
}

.user-dashboard {
    .user-actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .user-action {

        }
    }
}

.register-today-link {
    margin-top:15px;
}

.login-page, .register-screen {
    .card-body {
        padding:0;
    }
}

.register-screen {
    @include media-breakpoint-down(md){
        padding:0 1.5rem;
    }
}

input[type=password]{
    &.is-invalid {
        + .password-message {
            display:none;
        }
    }
}

.password-message {
    display:block;
    margin-top:-10px;
}

.login-form {
   &.card-body {
        @include media-breakpoint-down(md) {
            padding: 24px 28px;
        }
    }
}

.register-container {
    padding-top:24px;
    @include media-breakpoint-up(md){
        padding-top:48px;
    }
}
