.nav-stages-wrapper {

    margin:0 auto;
    width:100%;

    .status-nav-wrapper {
        padding:24px 0 0;
        @include media-breakpoint-up(md){
            padding:24px 20px 0;
        }
    }

    .steps-status {
        p {
            margin-top:0.5rem;
            @include media-breakpoint-up(md){
                margin-top:1rem;
            }
        }
    }

    .claim-nav {

        width: 100%;

        .navbar-nav, #navbarNav {
            width:100%;
        }

        .nav-item {

            background-color:#E6E6E6;
            width:20%;
            height:5px;
            margin-right:15px;

            @include media-breakpoint-up(md){
                height:10px;
            }


            &.active {
                background-color:$ba-red;
                color:$ba-blue;

            }


        }


    }

    .claim-nav-mobile {

        -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.8);
        box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.8);

        .mobile-nav-links {
            width:250px;
        }

        .container {
            padding-right:5px;
            padding-left:5px;
        }

        .nav-item {
            padding: 0.5rem 0.85rem;
            padding-right: 1rem;
            background-color: #80c2ce;

            display: none;

            &.active {
                width:85%;
                transition: all 500ms linear;
            }

            &.active, &.active + li {
                display: block;
                transition: all 0.5ms linear;

            }

            &.active + li, &.previous {
                padding: 0.5rem 0;

                span.num {
                    display: none;
                }

                a {
                    font-size: 0;

                    &:after {
                        content: '\f105';
                        font: normal normal normal 14px/1 FontAwesome;
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 20px;
                        padding-left: 0.1em;
                        font-weight: bold;
                        color: #fff;
                        text-align: center;
                        border-radius: 50%;
                        width: 1.5rem;
                        height: 1.5rem;
                        border: 1px solid #fff;

                    }
                }

                &:hover {
                    background: $ba-blue;
                }

            }

            &.previous {
                a {
                    font-size: 0;

                    &:after {
                        content: '\f104';

                    }
                }
            }

            a {
                color: #fff;
                display: inline-block;
            }

            span.num {
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                font-weight: bold;
                border: 1px solid #fff;
            }

            &.active, &:hover {
                background-color: #fff;
                color: $ba-blue;

                a {
                    color: $ba-blue;
                }

                span.num {
                    font-weight: bold;
                    border: 1px solid $ba-blue;
                    background: $ba-blue;
                    color: #fff;
                }


            }


        }
    }

}
