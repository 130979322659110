.bg-red {
    background-color:$ba-red;
    color:#fff;
}

.bg-dark-red {
    background-color:$ba-dark-red;
    color:#fff;
}

.bg-blue {
    background-color:$ba-blue;
    color:#fff;
}

.bg-dark-grey {
    background-color:$ba-dark-grey;
    color:#fff;
}

.bg-light-grey {
    background-color:$ba-light-grey;
    color:$ba-dark-grey;
}

.text-white {
    color:#fff;
    a{
        color:#fff;
    }
}

.text-red {
    color:$ba-red;
}

.bg-image {
    background-size: cover;
    background-position: center top;
    background-repeat:no-repeat;
}
