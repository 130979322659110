.admin-claims {

    .dataTables_wrapper .dataTables_filter input {
        margin-left: 0.5em;
        background-color: #ececec;
        border: none;
        padding: 8px;
    }

    .dt-buttons {
        margin-bottom:1.5rem;
    }

    table.dataTable {


        a {
            color:#575756;
            text-decoration: underline;
        }

        tbody {
            color:#575756;
            tr.odd {

            background-color: #F2F2F2;
        }

        tr.even {
            background-color:#FAFAFA;
        }
    }
    }

    .table-bordered th, .table-bordered td {
        border: 1px solid #fff;
    }

    table.dataTable tbody th, table.dataTable tbody td {
        padding: 12px;
    }

}
