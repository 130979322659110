.full-height {
    min-height: 100vh;
    overflow: hidden;
    &.claim-screen {
        overflow: hidden;
    }
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 25px;
    @include media-breakpoint-down(sm){
        top:15px;
    }
}

.content {
    text-align: center;
}

.title {
    font-size: 60px;
    padding-bottom:2rem;
}

.links > a, .links > li > a {

    .bg-red & {
        color:#fff;
    }

    color: #636b6f;
    padding: 0 25px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.m-b-md {
    margin-bottom: 30px;
}

p {
    &.large {
        font-size:1rem;
    }
}

.embed-container {
    height: 0;
    width: 100%;
    padding-bottom: 56.25%; /* play with this until right */
    overflow: hidden;
    position: relative;

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.responsive-object {
    width:100%;
    height: 100%;
    display:block;
}

.no-padding {
    padding:0;
}
