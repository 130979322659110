.action-buttons {
    padding:1.5rem;
    .btn {
        margin:0.75rem;
    }
}

.btn {
    @extend .btn-lg;
}

.btn-outline-ba {
    @extend  .btn-lg;
    border: solid 1px $ba-red;
    min-width: 150px;
    background:$white;
    color:$ba-red!important;
    line-height: 1.9;

    &.white {
        background:#fff;
    }

}

.ba-outline-buttons-lg {
    .btn {
        min-width: 155px;
        padding: 0.9rem;
        border-radius: 4px;
        margin: 0.25rem;
        background: none;
        height: 52px;
        line-height: normal;
    }
}

.btn-outline-ba-lg {
    min-width: 200px;
    padding: 0.9rem;
    border-radius: 2rem;
    margin: 0.25rem;
    background: none;
    border:solid 1px $ba-red;
    color:$ba-red;
}

.btn-yellow {
    background-color:#FBF3A2;
    color:$ba-red;
}

.btn-white {
    background-color:$white;
    color:$ba-red;
}

.btn-red {
    background-color:$ba-dark-red;
    color:$white;
    &:hover {
        color:$white;
    }
}

.btn-delete-proof-income {
    color:$white;
}

.btn-custom-upload {
    @extend .btn-outline-ba;
    cursor: pointer;
    font-weight: bolder;
    min-width:150px;
    text-align: center;
    padding: 0.75rem 1rem;
    background-color:$white;
    margin-top:15px;
}

.condition-wrapper, .custom-radio  {
    width: 100%;

    label {
        border:solid 1px $ba-red;
        width:100%;
        background:$white;
    }

    input[type="radio"] {
        display: none;
        &:checked {
            + .box {
                background-color: $ba-red;
                span {
                    color: white;
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .box {
        width:100%;
        height:48px;

        background-color: #fff;
        transition: all 250ms ease;
        will-change: transition;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        position: relative;
        font-family: Tahoma, sans-serif;
        display: flex;
        font-weight: bold;
        align-items: center;
        justify-content: center;

        span {
            transition: all 300ms ease;
            user-select: none;
            font-size: 1rem;
            color: $ba-red;
        }
    }
}

.custom-checkboxes  {
    width: 100%;
    margin-bottom:32px;

    label {
        border:solid 1px $ba-red;
        width:100%;
        background:$white;
    }

    input[type="checkbox"] {
        display: none;
        &:checked {
            + .box {
                background-color: $ba-red;
                span {
                    color: white;
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .box {
        width:100%;
        height:48px;

        background-color: #fff;
        transition: all 250ms ease;
        will-change: transition;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        position: relative;
        font-family: Tahoma, sans-serif;
        display: flex;
        font-weight: bold;
        align-items: center;
        justify-content: center;

        span {
            transition: all 300ms ease;
            user-select: none;
            font-size: 1rem;
            color: $ba-red;
        }
    }
}

.custom-checkboxes-round {

    label {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .label__description {
        p {
            margin-bottom:8px;
        }
    }

    .label__checkbox {
        display: none;
    }

    .label__text {
        display: flex;
    }

    .label__check {
        border-radius: 50%;
        border: 1px solid #e45f49;
        background: white;
        margin-right: 20px;
        margin-top: 5px;
        padding: 10px;
        width:1.75rem;
        height:1.75rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border 0.3s ease;

        i.icon {
            opacity: 0;
            font-size: 1rem;
            color: transparent;
            transition: opacity .3s .1s ease;
        }

    }

    .label__checkbox:checked + .label__text .label__check {
        animation: check .2s ease-in forwards;

        .icon {
            opacity: 1;
            transform: scale(0);
            color: white;
            -webkit-text-stroke: 0;
            animation: icon .2s cubic-bezier(1.000, 0.008, 0.565, 1.650) .1s 1 forwards;
        }
    }

    @keyframes icon {
        from {
            opacity: 0;
            transform: scale(0.5);
        }
        to {
            opacity: 1;
            transform: scale(1)
        }
    }

    @keyframes check {
        0% {
            border-width: 0px;
        }
        10% {
            opacity: 0.1;
            background: $ba-red;
        }
        12% {
            opacity: 0.4;
            background: $ba-red;
            border-width: 0;
        }
        50% {
            background: $ba-red;
            border: 0;
            opacity: 0.6;
        }
        100% {
            background: $ba-red;
            border: 0;
            opacity: 1;
        }
    }
}

.register-today-link {

}

.submit-button-group {
    margin-top:32px;
    @include media-breakpoint-down(md){
        text-align: center;
    }

    .btn-login {
        width:145px;
        height:52px;
    }

}

.next-block {
    padding-top:32px;

    @include media-breakpoint-down(md){
        display: flex;
        width: 100%;
        justify-content: space-between;
    }


.btn {
    min-width:145px;
    height:52px;
    @include media-breakpoint-up(md) {
        margin-right:15px;
    }
}
}

.legal {

    .next-block {
        padding-top:32px;

        @include media-breakpoint-down(md){
            ddisplay: flex;
            width: 100%;
            justify-content: space-between;
            padding: 15px;

        }

        .btn {
            min-width:145px;
            height:52px;
        }
    }

}

.register-buttons {
    > p {
        margin-top:15px;
    }
}

.start-claim {
    .claim-buttons {
        .btn {
            margin-bottom: 16px;
        }
    }
}

.btn-add-more {
    margin-top:16px;
}

input[type="checkbox"][readonly] {
    pointer-events: none;

        + .box {
            background-color: $ba-red;
            pointer-events: none;
            span {
                color: white;
                &:before {
                    opacity: 1;
                }
            }
        }

}
