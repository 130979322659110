body.claim {

.claim-income {

    .payee-wrapper {
        display:none;
        &.show-filter {
            display:block;
        }
        &.show-now {
            display:table-row;
        }
        label {
            display:block;
        }

        .delete-payee, .btn-del-payee-row {
            margin-bottom:15px;
        }

        .add-more-rows {
            font-weight: bold;
        }

    }

    .multiple-years, .previous-years {
        display:none;
        &.show-filter {
            display:block;
        }
        &.show-now {
            display:table-row;
        }
    }

    .checks-wrapper {
        margin-top:1rem;
        margin-bottom:1rem;
        max-width: 100%;
        padding: 8px 28px;

        > div {
            margin-bottom:16px;
        }

        input, label {
            display:inline;
        }

        label {
            padding-left:8px;
        }

    }
    .txt-children-wrapper, .txt-plans-wrapper {
        display:none;
        padding:15px 0 0;
        &.show-now {
            display:block;
        }
    }

    .txt-plans-wrapper {
        padding:15px 0;
        label {
            &:first-of-type {
                padding-left:0px;
            }
        }
        legend {
            display: block;
        }

    }

}

}

.income-docs-list, .docs-list {
    .fa {
        font-size:1.5rem;
        font-weight: 400;
    }
}

.claim-summary {

    .checks-wrapper {
        .fa {
            font-size: 22px;
            padding-right:0.35rem;
        }
    }

    .vertical-lables {
        td:nth-child(1), td:nth-child(3) {
            font-weight:bold;
        }
        td {
            padding:0.5rem;
        }

    }

    .card {
        border:none;
    }

    .card-header {
        background-color: inherit;
    }

    .card-header {
        border:none;
    }

    h4 {
        font-weight:400;
        &:before {
            content: "\f078";
            font: normal normal normal 21px/1 FontAwesome;
            color:$ba-blue;
            padding-right:0.5rem;

        }
    }
}

/* Admin */

.admin-claims, .admin-claims-wrapper {

    h4 {
        margin-bottom:1rem;
    }

    .vertical-lables td {
        padding: 0.1rem 0.5rem;
    }
}

.claim-wrapper {
    padding:0 28px 0;
    margin-top:-15px;

    h2 {
        padding-bottom:8px;
        font: normal normal normal 32px/42px Tahoma;
        font-family: Tahoma, sans-serif;
        color: #575656;
    }
    h3 {
        padding-bottom: 8px;
        font: normal normal normal 24px/32px Tahoma;
        font-family: Tahoma, sans-serif;
        color: #575656;
    }

    #chk_terms-error {
        display:block;
    }

    .docs-wrap {
        padding-bottom:64px;

        ul {
            margin-bottom:0;
        }

        .form-group {
            margin-bottom:0;
        }

        ul.proof-docs {
            margin-bottom:0;
            li {
                &:last-of-type {
                    padding-bottom:0;
                    margin-bottom:0;
                }
            }
        }

    }

    .strong {
        font-weight: bold!important;
    }

    b, strong {
        font-weight: bold!important;
    }

    .address-wrap {
        padding-top:64px;
        padding-bottom:64px;
    }

    .pre-claim-info {
        ul {
            padding-left:0;
            list-style: none;
            @include media-breakpoint-up(md){
                width:50%;
            }
            li {
                padding-left:40px;
                position:relative;
                &:before {
                    content: "\F00C";
                    font-family: "FontAwesome";
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    color: $white;
                    font-size: 16px;
                    padding-right: 2rem;
                    background: $green;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 15px;
                    vertical-align: middle;
                    padding: 5px;
                    display: inline-block;
                    position:absolute;
                    left:0;

                }
                h4, p {
                display:inline-block;
                font-family: Tahoma, sans-serif;
                font-size:16px;
                color:#575656;
                line-height:normal;
                }

                h4 {
                    font-weight: bold;
                }

            }
        }
    }

    .form-control-lg, .form-control {
        height: 48px;
        padding: 0.35rem 0.85rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.2rem;
        border: 1px solid #E6E6E6;
        background:#F2F2F2;

        &::placeholder {
            color:#A0A0A0;
        }

        &.error {
            border:solid 1px red;
        }

    }

}

.capital-expenses-table, .income-section, .payee-section {
    max-width:100%;
    background:#FCFCEC;
    padding:28px 28px 32px;
    margin-bottom:16px;
}

.add-another-wrap {
    text-align: center;
    @include media-breakpoint-up(md){
        text-align: left;
    }
}

.capital-expenses-table, .income-section, .payee-section {

    .expenses-row, .expense-row {

    }

    label {
        margin-bottom:8px;
        &.no-border {
            border:none;
            background-color: inherit;
        }
    }

    .select {
        position: relative;
        &:after {
            font-family: FontAwesome;
            content: '\f107';
            font-size: 24px;
            font-weight: bold;
            position: absolute;
            top: 7px;
            right: 15px;
            color: $ba-red;
            pointer-events: none;

        }

    }

    select {
        border:solid 1px $ba-red;
        color:$ba-red;
        width:100%;
        height:52px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E45F49;
        border-radius: 6px;
        margin-bottom:32px;
        padding:0 1rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.error {
            border:solid 1px red;
        }

    }

    input[type=text] {

        background: #F2F2F2 0% 0% no-repeat padding-box;
        border: 1px solid #E6E6E6;
        border-radius: 4px;
        height:48px;
        width: 100%;
        margin-bottom:32px;
        padding:0 1rem;

        &.error {
            border:solid 1px red;
        }

    }

    h4 {
        font-weight:bold;
        font-size:1rem;
    }



}

.income-section {
    width:100%;
}

select.ba, input[type=text].ba {
    border: solid 1px $ba-blue;
    min-width: 150px;
    padding: 0.25rem;
    height: 34px;

    &.error {
        border: solid 1px red;
    }
}

.custom-upload {

    .btn-file {
        position: relative;
        overflow: hidden;
    }
    .btn-file input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
        display: block;
    }

}

.buttons-block {
    padding-top:1rem;
    padding-bottom:1.5rem;
    text-align: center;

    @include media-breakpoint-up(md){
        text-align: left;
    }

    .btn {
        min-width:250px;
    }
}

.proof-docs {
    a.btn {
        padding: 0.25rem 0.5rem;
        font-size: 0.7875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
        color:$white;
    }
}

.expenses-message, .success-message {
    color: #1d643b;
    background-color: #d7f3e3;
    border-color: #c7eed8;
    padding: 1rem;
    display:none;
    i {
        &.fa {
            background-color:$green;
            color:$white;
            border-radius:50%;
            padding:0.15rem;
        }
    }
}

.error-message {
    color: $white;
    background-color: $ba-red;
    border-color: #c7eed8;
    padding: 1rem;
    display:none;
    i {
        &.fa {
            background-color:$green;
            color:$white;
            border-radius:50%;
            padding:0.15rem;
        }
    }
}

.expenses-list {
    .form-row {
        padding-bottom:32px;

        input[type=text]{
            background-color: $ba-light-grey;
            border: 1px solid #E6E6E6;
            border-radius: 4px;
            padding:15px 16px;
            width:100%;
            &.error {
                border: 1px solid red;
            }
        }

    }

    h6 {
        font: normal normal normal 16px/18px Tahoma;
        font-family: Tahoma, sans-serif;
    }

}

.income-file-container {

    width: 100%;

    .message-wrapper {
        @include media-breakpoint-down(md){
            text-align: center;
        }
    }

    .controls {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 10px 0 20px;

        @include media-breakpoint-down(md){
            justify-content: center;
        }

        .btn-custom-upload, select {
            height:52px;
            width:150px;
            margin:0;
            font-weight: normal;
            padding:0;
            vertical-align: middle;
            padding:0.5rem;
        }

        .btn-custom-upload {
            margin-left:19px;
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent;
            background-image: url("data:image/svg+xml;utf8,<svg fill='red' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 95%;
            background-position-y: 50%;
            padding-left: 4%;

        }

    }
}

.next-block {
    .submit-claim {
        @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    }
}

.income-section {
    padding-top:28px;
}
