html, body {
    background-color: #fff;
    color: #636b6f;
    font-family: Tahoma, sans-serif;
    font-size: 16px;
    font-weight: 300;
    height: 100vh;
    margin: 0;
}

body {
    &.login {
        @include media-breakpoint-up(lg) {

            footer {

                width:100%;
            }
        }
    }
}

.navbar {
    padding: 0.5rem;
    @include media-breakpoint-up(sm){
        padding:10px 1rem;
    }
}

.login-page {
    &.full-height {
        @include media-breakpoint-down(sm){
            min-height:80vh;
        }
    }
}

.brand-image {
    &.full-height {
        @include media-breakpoint-down(sm){
            min-height:50vh;
        }
    }
}

.navbar-expand .navbar-nav .dropdown-menu {
    background: $ba-red;
}

.logo {
    &.align-top {
        @include media-breakpoint-down(sm) {
            vertical-align: baseline!important;
        }
    }
}

.logo img {
    max-width:150px;
    @include media-breakpoint-up(sm){
        max-width:177px;
    }
}

.account-sidebar {
    padding:0 1rem;
}

.sidenav {
    height: 100%;
    width: 0;
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: $ba-dark-grey;
    overflow-x: hidden;
    transition: all 0.25s ease-in-out;
    padding-top: 55px;
    -webkit-box-shadow: 11px 3px 11px -6px rgba(0,0,0,0.76);
    box-shadow: 11px 3px 11px -6px rgba(0,0,0,0.76);

    .links-wrapper {
        padding:0 2rem;
        text-align: center;
        a {
            padding:0.5rem 1.5rem;
            text-decoration: none;
            font-size: 16px;
            color: #fff;
            display: block;
            transition: 0.3s;
        }
    }

}

.sidenav a {

}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 19px;
    background: #e45f49;
    color: #fff;
    padding:5px 20px;
    float: right;
    border-radius: 0 0 0 2px;
    display: block;
    text-decoration: none;

    a {
        i {
            font-size:19px;
            font-weight: normal;
        }
    }

}

#main {

}

.menu-button {
    font-size:25px;
    cursor:pointer;
}

.account-button {
    font-size: 1.75rem;
    color: #fff;
    margin-right:1.5rem;
}

@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

.footer-copyright {
    .social-links a{
        text-align: center;
        float: left;
        width: 36px;
        height: 36px;
        border: 1px solid #575656;
        border-radius: 100%;
        margin-right: 7px; /*space between*/

    }
    .social-links a i{
        font-size: 20px;
        line-height: 38px;
        color: #575656;
    }
}

.receipts-wrapper {
    display:none;
    &.hide {
        display:none;
    }
    &.show {
        display:block;
    }
}

.na-buttons-wrapper {
    @include media-breakpoint-down(sm){
        .account-button {
            display:none;
        }
    }
}

.user-dashboard {

   ul#expenses-doc-list {
       padding-left:15px;
       li  {
           margin-bottom:0.5rem;
           > a {
               font-size: 15px;
           }
       }
   }
}

.start-claim {
    padding-top:24px;
}

.nav-links-wrapper {

    .buttons-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .phone-icon {
        display: inline-block;
        height: 25px;
        a {
            padding-right:10px;
            img {
                width: 25px;
                height: 25px;
                margin-top:-2px;
            }
        }
    }
}

.menu-button {
    font-size: 30px;
    cursor: pointer;
}

.login-screen {
    height:100%;
    position: relative;

    .login-form {
        padding-top:64px;
    }


    .brand-image {
        position: relative;
        height:93vh;

        .bg-image {
            height:100%;
            width:100%;
            position: absolute;
            top:0;
            bottom:0;
            right:0;
            left:0;
            width:100%;
            height:100%;

        }

    }

}

body.login {
    height:auto;
    overflow-x: hidden;
    footer {
       position: absolute;
       bottom:0;
    }

    .login-page {
        padding:48px 0;
    }

}


.register-container {
    padding-bottom:48px;
    margin-bottom:48px;
}
